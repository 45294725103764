.App {
  text-align: center;
}

.gender-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.gender-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 25px 50px;
}

button {
  height: 25px;
  border-radius: 15px;
  margin: 5px 0px;
}

.button-selected {
  background-color: dodgerblue;
}